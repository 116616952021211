import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Button } from '@material-ui/core';
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';
import { Link } from 'react-router-dom';


const ManageEventList = ( { events, uid, org }) => {
  const { deleteDocument } = useFirestore('orgs')
  const { deleteDocumentToMain } = useMainFirestore()

  const handleDelete = (event) => {
    deleteDocument(uid, 'Events', event.id)
    deleteDocumentToMain(org, "OrgEvent", event.tyneProjectDocID)
  }


  return (
    <>
      <h2 style={{textAlign: "center"}}>Manage Events</h2>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Location</TableCell>
            <TableCell align="center">Event Date</TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">Image</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
           
            <TableRow
              key={event.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {event.title}
              </TableCell>
              <TableCell align="center" style={{width: "45%"}}>{event.description}</TableCell>
              <TableCell align="center">{event.location}</TableCell>
            
              <TableCell align="center">{event.startDate.toDate().toDateString()}</TableCell>
              <TableCell align="center">{event.startTime}</TableCell>
              <TableCell align="center"><img src={event.imageURL} alt='' style={{width: '75%'}}/></TableCell>
              <TableCell align="center">
              <Link
              to={{
                pathname: "/event/edit/"+event.id,
                state: { 
                  eventTitle: event.title,
                  titleArray: event.title.split(' '),
                  location: event.location,
                  description: event.description,
                  eventID: event.id,
                  tyneProjectDocID: event.tyneProjectDocID,
                  eventOwnerID: event.owner
                }
              }}
            
              >Edit</Link>
               
                <Button size="small" variant="contained" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDelete(event) } }>Delete</Button>
              </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
};

export default ManageEventList;
