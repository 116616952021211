import { useReducer, useEffect, useState } from "react";
import { mainFirestore } from "../firebase";

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { isPending: true, document: null, success: false, error: null}
        case 'ADDED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null}
        case 'DELETED_DOCUMENT':
            return { isPending: false, document: null, success: true, error: null }
        case 'ERROR':
            return { isPending: false, document: null, success: false, error: action.payload}
        default:
            return state
    }
}
export const useMainFirestore = () => {
    const [responseFromMain, dispatch] = useReducer(firestoreReducer, initialState)
    const [isCancelled, setIsCancelled] = useState(false)

    // collection ref
    const ref = mainFirestore.collection("College")

    // Only dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // Category: Banners/Events/Announcements
    const addDocumentToMain = async (collegeName, orgID, category, doc) => {
        
        dispatch({ type: 'IS_PENDING' })

        try {
            const docID = ref.doc(collegeName).collection(category).doc().id
            const ref_to_add = ref.doc(collegeName).collection(category).doc(docID)
            const addedDocument = await ref_to_add.set({ ...doc, docID ,orgID })
            dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })
            return docID
        }
        catch (err) {
            console.log(err)
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message })
            return null
        }
        
    }

    const deleteDocumentToMain = async (org, category, docID) => {
        dispatch({ type: 'IS_PENDING'})
        
        try {
            const ref_to_delete = ref.doc(org.collegeAcronym).collection(category).doc(docID)
            await ref_to_delete.delete()
            dispatchIfNotCancelled( {type: 'DELETED_DOCUMENT'})
        }
        catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message })
        }
    }

    const updateDocumentToMain = async (org, category, docID, banner) => {
        dispatch({ type: 'IS_PENDING'})
        try {
            const ref_to_update = ref.doc(org.collegeAcronym).collection(category).doc(docID)
            const updated_document = await ref_to_update.update({...banner})
            dispatchIfNotCancelled( {type: 'UPDATED_DOCUMENT', payload: updated_document})
        }
        catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message })
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return {addDocumentToMain, deleteDocumentToMain, updateDocumentToMain,  responseFromMain}
}