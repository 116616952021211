import React from 'react'
import * as FaIcons from 'react-icons/fa'
import * as RiIcons from 'react-icons/ri'

export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <FaIcons.FaUser />,
        iconClosed:<RiIcons.RiArrowDownSFill />,
        iconOpened:<RiIcons.RiArrowUpSFill />,
    },
    {
        title: 'Banners',
        icon: <FaIcons.FaSign />,
        iconClosed:<RiIcons.RiArrowDownSFill />,
        iconOpened:<RiIcons.RiArrowUpSFill />,
        path: '#',
        subNav: [
            {
                title: 'Upload Banner',
                path: '/banner/upload',
                icon: <FaIcons.FaPlusSquare />,
            },
            {
                title: 'Manage Banners',
                path: '/banner/manage',
                icon: <FaIcons.FaRegEdit />,
            },
        ]
    },

    {
        title: 'Announcements',
        icon: <FaIcons.FaLightbulb />,
        iconClosed:<RiIcons.RiArrowDownSFill />,
        iconOpened:<RiIcons.RiArrowUpSFill />,
        path: '#',
        subNav: [
            {
                title: 'New Announcement',
                path: '/announcement/new',
                icon: <FaIcons.FaPlusSquare />,
            },
            {
                title: 'Manage Announcements',
                path: '/announcement/manage',
                icon: <FaIcons.FaRegEdit />,
            },
        ]
    },

    {
        title: 'Events',
        icon: <FaIcons.FaFlagCheckered />,
        iconClosed:<RiIcons.RiArrowDownSFill />,
        iconOpened:<RiIcons.RiArrowUpSFill />,
        path: '#',
        subNav: [
            {
                title: 'New Event',
                path: '/event/new',
                icon: <FaIcons.FaPlusSquare />,
            },
            {
                title: 'Manage Events',
                path: '/event/manage',
                icon: <FaIcons.FaRegEdit />,
            },
        ]
    },

    {
        title: 'Subscription',
        path: '/subscribe',
        icon: <FaIcons.FaDollarSign />,
        iconClosed:<RiIcons.RiArrowDownSFill />,
        iconOpened:<RiIcons.RiArrowUpSFill />,
    },


    {
        title: 'Contact Tyne',
        path: '/contact',
        icon: <FaIcons.FaRegEnvelope />,
        iconClosed:<RiIcons.RiArrowDownSFill />,
        iconOpened:<RiIcons.RiArrowUpSFill />,
    },

    {
        title: 'Log Out',
        path: '/',
        icon: <FaIcons.FaSignOutAlt />,
        iconClosed:<RiIcons.RiArrowDownSFill />,
        iconOpened:<RiIcons.RiArrowUpSFill />,
    }



]