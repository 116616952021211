import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button'
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';
import TextField from '@mui/material/TextField';

import getCroppedImg from '../components/Crop/CropImage'
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';
import { mainStorage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom'

import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import moment from 'moment';

import RestrictedAccess from '../pages/RestrictedAccess';
import '../pages/Event.css'

export const EditEvent = ( { uid, org }) => {
   
    const history = useHistory()

    // States
    const [eventTitle, setEventTitle] = useState(null);
    const [titleArray, setTitleArray] = useState([]);
    const [location, setLocation] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [startDateRaw, setStartDateRaw] = useState(null);
    const [startTimeRaw, setStartTimeRaw] = useState(null);
    const [description, setDescription] = useState(null );

    // Passed state for edit
    const [eventID, setEventID] = useState(null);
    const [tyneProjectEventID, setTyneProjectEventID] = useState(null);
    const [eventOwnerID, setEventOwnerID] = useState(null);
    // Firestore
    const { updateDocument, response } = useFirestore('orgs')
    const { updateDocumentToMain } = useMainFirestore()

    // Alert 
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
     
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertMessage('')
        setOpenAlert(false);
    };
     
    // Loading 
    const [isLoading, setIsLoading] = useState(false);
    const handleStopLoad = () => {
        setIsLoading(false);
    };
    const handleStartLoad = () => {
        setIsLoading(true);
    };
    

    let stateLocation = useLocation()

    useEffect(() => {
        if (stateLocation && stateLocation.state) {
            setEventTitle(stateLocation.state.eventTitle)
            setTitleArray(stateLocation.state.titleArray)
            setLocation(stateLocation.state.location)
            setDescription(stateLocation.state.description)
            setEventID(stateLocation.state.eventID)
            setTyneProjectEventID(stateLocation.state.tyneProjectDocID)
            setEventOwnerID(stateLocation.state.eventOwnerID)
        }
        
    }, [stateLocation])

    const handlePublish = () => {
        const currentDate = moment(Date());
        const subEndDate = moment(org.membershipEndDate.toDate());
        const isAfter = currentDate.isAfter(subEndDate, "day")

        if (isAfter) {
            setOpenAlert(true)
            setAlertMessage("You must be subscribed to a plan to publish an event")
            return;
        }

        if (eventTitle === null || eventTitle === "" || titleArray.count === 0) {
            setOpenAlert(true)
            setAlertMessage("Event title is empty")
            return;
        } 

        if (location === null || location === "") {
            setOpenAlert(true)
            setAlertMessage("Event location is empty")
            return;
        } 

        if (startDate === null || startDateRaw === null) {
            setOpenAlert(true)
            setAlertMessage("Start date is empty")
            return;
        }

        if (startTimeRaw === null || startTimeRaw === "") {
            setOpenAlert(true)
            setAlertMessage("Start time is empty")
            return;
        }

        if (image === null) {
            setOpenAlert(true)
            setAlertMessage("You must choose an image")
            return;
        }
        
        const storageRef = mainStorage.ref()
        const imageUUID = uuidv4();
        const filePath = 'Orgs/'+uid+'/'+imageUUID+'.png'
        const fileRef = storageRef.child(filePath)
        const uploadTask = fileRef.put(imgBlob)

        handleStartLoad()
        
        uploadTask.on("state_changed",
            (snapshot) => {},
            (error) => {
                handleStopLoad()
                console.log(error.message)
            },
            () => {
                mainStorage.ref(filePath)
                .getDownloadURL()
                .then((url) => {
                    updateDocumentToMain(org, 'OrgEvent', tyneProjectEventID, {
                        title: eventTitle,
                        title_array: titleArray,
                        location: location,
                        images: [url],
                        description: description,
                        start_date: startDate,
                        start_time: startTimeRaw
                    }).then(() => {
                        handleStopLoad()
                        updateDocument(uid, 'Events', eventID, {
                            title: eventTitle,
                            location: location,
                            startDate: startDate,
                            startTime: startTimeRaw,
                            description: description,
                            imageURL: url,
                        })
                    })
                })
            }
        )
    }

    useEffect(() => {
        if (response.success) {
           history.push('/event/manage')
        }
    }, [response.success, history])

    const handleTitleChanged = (e) => {
        setEventTitle(e.target.value);
        var arr = e.target.value.split(' ');
        setTitleArray(arr)
    }

    const handleLocationChanged = (e) => {
        setLocation(e.target.value);
    }

    const handleStartDateChanged = (e) => {
        setStartDateRaw(e.target.value)
        const date = new Date(e.target.value)
        setStartDate(date)
    }

    const handleStartTimeChanged = (e) => {
        setStartTimeRaw(e.target.value)
    }

    const handleDescriptionChanged = (e) => {
        setDescription(e.target.value);
    }

    // Crop States
    const [openCrop, setOpenCrop] = useState(false)
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [image, setImage] = useState(null);
    const [imgBlob, setImgBlob] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({x:0, y:0});
    const [zoom, setZoom] = useState(1)

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixles) => {
        setCroppedArea(croppedAreaPixles);
    }

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.addEventListener('load', ()=> {
                setImage(reader.result);
            });
        }
    };

    const triggerCrop = async() => {
        const canvas = await getCroppedImg(image, croppedArea);
        canvas.toBlob(callback => {
            setImage(canvas.toDataURL('image/png', 1.0))
            setImgBlob(callback)
            setOpenCrop(false);
        }, 'image/png', 1.0)
        
        
    }
    return (
        
            openCrop ?
            <div className='CropContainer'>
            <div className='container-cropper'>
            {image ? (
                    <>
                    <div className='cropper'>
                        <Cropper 
                        image={image} 
                        crop={crop} 
                        zoom={zoom} 
                        aspect={3/4} 
                        onCropChange={setCrop} 
                        onZoomChange={setZoom} 
                        onCropComplete={onCropComplete}
                        />
                    </div>
                    <div className='slider'>
                        <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)}></Slider> 
                    </div>
                    </>
                ) : null}
            </div>
            <div className='container-buttons'>
                <input 
                    type='file' 
                    accept="image/jpg, image/jpeg, image/png" 
                    ref={inputRef} 
                    style={{display:'none'}}
                    onChange={onSelectFile} 
                />

                <Button 
                    variant='contained' 
                    onClick={triggerFileSelectPopup}
                    style={{marginRight: "10px"}}
                >
                    Choose New Image 
                </Button>

                <Button 
                    variant='contained'
                    onClick={triggerCrop}
                >
                    Save
                </Button>
            </div>
        </div>

            :
            (eventOwnerID === uid) ? (
            <div className="NewEvent">
                
                {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}

                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>

                <div className="NewEventWrapper">
                <h1>Edit Event</h1>
                    <div className="NewEventBox">
                        <p className="TitleInfo">Title</p>
                        <input placeholder='Title' className='TextFieldInput' value={eventTitle ? eventTitle : ""} onChange={handleTitleChanged}/>

                        <p className="TitleInfo">Location</p>
                        <input placeholder='Title' className='TextFieldInput' value={location ? location : ""} onChange={handleLocationChanged}/>

                        <p className='dateTitle'>Date</p>
                        <input type="date" onChange={handleStartDateChanged} value={startDateRaw ? startDateRaw : ""} className='DatePicker'/>
                        <p className='dateTitle'>Time</p>
                        <input type="time" onChange={handleStartTimeChanged} value={startTimeRaw ? startTimeRaw : ""} className='DatePicker'/>

                        <p className='TitleInfo'>Description</p>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Describe here"
                            multiline
                            maxRows={10}
                            value={description ? description : ""}
                            onChange={handleDescriptionChanged}
                         />

                        <p className="TitleInfo">Attach Event Image (4:3 Image Ratio)</p>
                        <button className="CropButton" onClick={setOpenCrop}>Select Image</button>
                        <img src={image} alt='' className='Event-CroppedImage'></img>

                    
                        <button className="PublishButton" disabled={isLoading} onClick={handlePublish}>
                            Publish
                        </button>
                    </div>
                </div>
            </div>
            )
            :
            <RestrictedAccess />
        );
    };
