import React from 'react';
import './Contact.css'
import emailjs from 'emailjs-com'
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';

const ContactUs = ({org}) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleStopLoad = () => {
        setIsLoading(false);
      };
      const handleStartLoad = () => {
        setIsLoading(true);
      };
  
  function sendEmail(e) {
    e.preventDefault();
    handleStartLoad()
    emailjs.sendForm('service_yk42gpx', 'template_vau5mxu', e.target, 'user_K78N0kkSKqRBVAFwj7oIq'
    ).then(res=> {
        handleStopLoad()
        document.getElementById("contact-form").reset();
        alert("Your message has been successfully sent!");
    }).catch(err=> {
        handleStopLoad()
        console.log(err)
        alert("An error occured while trying to send your message");
    });
  }
  return (
    <>
        <div className='container border ContainerBorder'
            style = {{ marginTop: "50px"
        }} >
            <h1 className='contactTitle'>Contact Tyne</h1>
            <form className="form-row" id="contact-form" onSubmit={sendEmail}>
                
                <label className="contact-form-label">Message</label>
                <textarea name="message" rows='4' className='form-control'/>
                <input type="hidden" name="org_name" value={org.name} />
                <input type="hidden" name="college_name" value={org.college} />
                <input type="hidden" name="org_email" value={org.email} />
                <input type='submit' value='Send'className='form-control' btn btn-primary
                style={{marginTop: "30px", backgroundColor: "#ea2a55", color: "white", fontWeight: "600"}}
                />
            </form>
            <Backdrop
                sx={{ color: '#fff', zIndex:2}}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>

        
  </>
  )
};

export default ContactUs;
