import { useEffect, useState } from "react";
import { projectAuth, firebase, projectFirestore } from "../firebase";

export const useProfileManager = () => {
    
    // Category: Banners/Events/Announcements
    const authenticateCurrentPassword = async (currentPassword) => {

        try {
            const cred = firebase.auth.EmailAuthProvider.credential(projectAuth.currentUser.email, currentPassword)
            const authenticated = await projectAuth.currentUser.reauthenticateWithCredential(cred)
            if (authenticated) {
                return true
            } else {
                return false
            }
        }
        catch (err) {
            return false
        }
    }

    const updatePassword = async (newPassword) => {
        try {
            await projectAuth.currentUser.updatePassword(newPassword);
            return true;
        }
        catch (err) {
            return false;
        }
    }

    const updateEmail = async (orgDocID, email) => {
        try {
            await projectFirestore.collection("orgs").doc(orgDocID).update({
                email,
            })
            await projectAuth.currentUser.updateEmail(email)
            return true
        } 
        catch (err) {
            console.log(err)
            return false;
        }
    }
    
    return {authenticateCurrentPassword, updatePassword, updateEmail}
}