import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import { useLogout } from '../../hooks/useLogout'

const SidebarLink = styled(Link)`
    display:flex;
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        background: rgb(196, 29, 66, 0.6);
        border-left: 4px solid #632ce4;
        cursor: pointer;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    background: #ea2a55;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;

    &:hover {
        background: rgb(196, 29, 66, 0.6);
        cursor: pointer;
    }
`
const SubMenu = ({ item, closeHandler }) => {
    const { logout } = useLogout()
    const [subnav, setSubnav] = useState(false)

    const showSubnav = () => setSubnav(!subnav)

    const handleSidebarLinkClick = () => {
        if (item.title === "Log Out") {
            logout()
        } else {
            if (item.subNav) {
                showSubnav()
            } else {
                closeHandler()
            }
        }
     }

    return (
        <>
            <SidebarLink to={item.path} onClick={() => handleSidebarLinkClick()}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </SidebarLink>

            {subnav && item.subNav.map((item, index) => {
                return (
                    <DropdownLink to={item.path} key={index} onClick={closeHandler}>
                        {item.icon}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </DropdownLink>
                )
            })}
        </>
    )
} 

export default SubMenu