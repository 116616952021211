import { useReducer, useEffect, useState } from "react";
import { projectFirestore, timestamp } from "../firebase";

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { isPending: true, document: null, success: false, error: null}
        case 'ADDED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null}
        case 'UPDATED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null }
        case 'DELETED_DOCUMENT':
            return { isPending: false, document: null, success: true, error: null }
        case 'ERROR':
            return { isPending: false, document: null, success: false, error: action.payload}
        default:
            return state
    }
}
export const useFirestore = (collection) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    const [isCancelled, setIsCancelled] = useState(false)

    // collection ref
    const ref = projectFirestore.collection(collection)

    // Only dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // Category: Banners/Events/Announcements
    const addDocument = async (orgID, category, doc) => {
        dispatch({ type: 'IS_PENDING' })

        try {
            const created = timestamp.fromDate(new Date())
            const ref_to_update = ref.doc(orgID).collection(category)
            const addedDocument = await ref_to_update.add({ ...doc, created })
            dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })
        }
        catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message })
        }
    }

    const deleteDocument = async (orgID, category, docID) => {
        dispatch({ type: 'IS_PENDING'})

        try {
            const ref_to_delete = ref.doc(orgID).collection(category).doc(docID)
            await ref_to_delete.delete()
            dispatchIfNotCancelled( {type: 'DELETED_DOCUMENT'})
        }
        catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message })
        }
    }

    const updateDocument = async (orgID, category, docID, banner) => {
        dispatch({ type: 'IS_PENDING'})

        try {
            const ref_to_update = ref.doc(orgID).collection(category).doc(docID)
            const updated_document = await ref_to_update.update({...banner})
            dispatchIfNotCancelled( {type: 'UPDATED_DOCUMENT', payload: updated_document})
        }
        catch (err) {
            dispatchIfNotCancelled({ type: "ERROR", payload: err.message })
        }
    }

    const checkBannerExists = async (orgID, startTime, endTime) => {
        // Logic 
        // If either startTime or endTime is between any banners' startDate -- endDate, then there must be a overlap
        try {
            const startTimeDocRefs = await ref.doc(orgID).collection("Banners")
            .where("startDate", ">=", startTime)
            .where("startDate", "<=", endTime)
            .get()
            if (startTimeDocRefs.docs.length > 0) {
                return false
            } else {
                const endTimeDocRefs = await ref.doc(orgID).collection("Banners")
                    .where("endDate", ">=", startTime)
                    .where("endDate", "<=", endTime)
                    .get()
                if (endTimeDocRefs.docs.length > 0) {
                    return {result: false, error: null}
                } else {
                    return {result: true, error: null}
                }
            }
        } catch (err) {
            console.log(err)
            return {result: false, error: err}
        }
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return {addDocument, deleteDocument, updateDocument, checkBannerExists, response}
}