import React, { useState } from 'react'
import axios from 'axios';
import './Subscription.css'
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import { Alert, Snackbar } from '@mui/material';
import {projectFirestore} from '../firebase'
import ReactLoading from 'react-loading'
import moment from 'moment';

const Subscription = ({org}) => {

      // Alert 
      const [openAlert, setOpenAlert] = useState(false);
      const [alertMessage, setAlertMessage] = useState('');
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertMessage('')
        setOpenAlert(false);
    };

    // Loading 
    const [isLoading, setIsLoading] = useState(false);
    const handleStopLoad = () => {
        setIsLoading(false);
    };
    const handleStartLoad = () => {
        setIsLoading(true);
    };

    const headers = {
    'Content-Type': 'application/json',
    }

    /*
    const handleDidSelectSubscription = async (planID) => {
        
        handleStartLoad()

        const currentDate = moment(Date());
        const subEndDate = moment(org.membershipEndDate.toDate());
        const isAfter = currentDate.isAfter(subEndDate, "day")

        const orgRef = await projectFirestore.collection("orgs").doc(org.uid).get()
        handleStopLoad()
        if (orgRef.data().subscriptionID !== "" && !isAfter) {
            setOpenAlert(true)
            setAlertMessage("You are already subscribed")
            return;
        } else {
            if (planID === 0) {
                axios.post("https://us-central1-tyne-orgs.cloudfunctions.net/app/create-checkout-session", {
                    customerID: orgRef.data().stripeCustomerID,
                    membershipType: 0,
                    prevBillingPeriod: orgRef.data().membershipEndDate,
                  }, {
                    headers: headers,
                })
                .then(function(response) {
                    if (response.data.success === true) {
                        window.location = response.data.url
                    } else {
                        setOpenAlert(true)
                        setAlertMessage("Failed to load checkout session. Please try again later.")
                        return;
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
            } else if (planID === 1) {
                axios.post("https://us-central1-tyne-orgs.cloudfunctions.net/app/create-checkout-session", {
                customerID: orgRef.data().stripeCustomerID,
                membershipType: 1,
              }, {
                headers: headers,
            })
            .then(function(response) {
                if (response.data.success === true) {
                    window.location = response.data.url
                } else {
                    setOpenAlert(true)
                    setAlertMessage("Failed to load checkout session. Please try again later.")
                    return;
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            }
        }
    }
    */
    const handleDidSelectSubscription = () => {
        setAlertMessage('Subscription plan not yet enabled. We will let you know when this is enabled. You can still use the service until the end of 2022.')
        setOpenAlert(true);
    }
    
    const card = (title, price, subtitleOne, subtitleTwo) => (
       <>
       <div style={{display: "flex", flexDirection: "column"}}>
            <CardContent>
                <p style={{fontSize: "20px", color: "rgb(144, 158, 179)", fontWeight: "500"}}>{title}</p>
                <p style={{fontSize: "28px", color: "#ea2a55", fontWeight: "600"}}>{price}</p>
                <Typography sx={{ mb: 1.5 }} style={{color:"rgb(144, 158, 179)"}}>
                {subtitleOne}
                </Typography>
                <Typography sx={{ mb: 1.5 }} style={{color:"rgb(144, 158, 179)"}}>
                {subtitleTwo}
                </Typography>
            </CardContent>
       </div> 
       </>
          
      );

  return (
    <div className="SubscriptionContainer">
                
                <div className="SubscriptionWrapper">
                <h1>Subscription</h1>
                    {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}

                    <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                    <div className="SubscriptionBox">
                        <Card variant="outlined" className='Subscription-Card'>
                            {card("Basic-Monthly", "$5.00", "Per month", "Billed Monthly")}
                            <div style={{display: "flex", justifyContent: "center", paddingBottom: "1.5rem"}}>
                                <Button variant='contained' 
                                    style={{
                                    backgroundColor: "#ea2a55",
                                    color: "#fff",
                                    width: "50%",
                                    textAlign: "center",
                                    }}
                                    onClick={() => {
                                        // handleDidSelectSubscription(0)
                                        handleDidSelectSubscription();
                                    }}
                                >Select</Button>
                            </div>
                        </Card>

                        <Card variant="outlined" className='Subscription-Card'>
                            {card("Basic-Quarterly", "$17.00", "Per quarter", "Billed Quarterly")}
                            <div style={{display: "flex", justifyContent: "center", paddingBottom: "1.5rem"}}>
                                <Button variant='contained' 
                                    style={{
                                    backgroundColor: "#ea2a55",
                                    color: "#fff",
                                    width: "50%",
                                    textAlign: "center",
                                    }}
                                    onClick={() => {
                                        // handleDidSelectSubscription(1)
                                        handleDidSelectSubscription();
                                    }}
                                >Select</Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
  )
}

export default Subscription