import firebase from 'firebase/app';
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

const orgAppConfig = {
    apiKey: "AIzaSyC5yyiFakjCB4uWtpX2djEMGChCkFYGwNI",
    authDomain: "tyne-orgs.firebaseapp.com",
    projectId: "tyne-orgs",
    storageBucket: "tyne-orgs.appspot.com",
    messagingSenderId: "75088886481",
    appId: "1:75088886481:web:b281b6f1bd646615d1a3f0",
    measurementId: "G-B3SYK5W5PT"
}

const mainFirebaseConfig = {
    apiKey: "AIzaSyD9FzoD9JmJjumwkLwfv0NFToAQWOsWuF4",
    authDomain: "kite-5abad.firebaseapp.com",
    databaseURL: "https://kite-5abad-default-rtdb.firebaseio.com",
    projectId: "kite-5abad",
    storageBucket: "kite-5abad.appspot.com",
    messagingSenderId: "201352003480",
    appId: "1:201352003480:web:fa69cd327d31090da4b5ed",
    measurementId: "G-8TCDCNL310"
}

const mainApp = firebase.initializeApp(mainFirebaseConfig, "main");
const orgApp = firebase.initializeApp(orgAppConfig, "org");

const projectFirestore = orgApp.firestore()
const projectAuth = orgApp.auth()
const mainFirestore = mainApp.firestore();
const mainStorage = mainApp.storage();

const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, mainFirestore, mainStorage, timestamp, firebase}
