import { createContext, useEffect, useReducer} from 'react'
import { projectAuth, projectFirestore } from '../firebase'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload }
        case 'LOGOUT':
            return { ...state, user: null }
        case 'AUTH_IS_READY':
            return { ...state, user: action.payload, authIsReady: true}
        case 'RETRIVED_ORG':
            return { ...state, org: action.payload }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        org: null,
        authIsReady: false
    })

    useEffect(() => {
        const unsub = projectAuth.onAuthStateChanged((user) => {
            dispatch({ type: 'AUTH_IS_READY', payload: user})
            unsub()
        })
    }, [])
  
    useEffect(() => {
        if (state.authIsReady && state.user) {
            projectFirestore.collection('orgs').doc(state.user.uid).get()
            .then(snapshot => {
                dispatch({ type: 'RETRIVED_ORG', payload: snapshot.data()})
            })
        }
    }, [state.authIsReady, state.user])

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}