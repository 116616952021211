import React, { useEffect, useState } from 'react'
import './ChangePassword.css'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { useProfileManager } from '../hooks/useProfileManager';

import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

const ChangeEmail = ({ org }) => {
    const history = useHistory()
    const { authenticateCurrentPassword, updateEmail } = useProfileManager()
    
    // States
    const [currentPasswordValues, setCurrentPasswordValues] = useState({
        password: "",
        showPassword: false,
    });
    const [newEmail, setNewEmail] = useState("")
    const [confirmEmail, setConfirmEmail] = useState("")

    // Alert 
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertMessage('')
        setOpenAlert(false);
    };

    // Loading 

    const [isLoading, setIsLoading] = useState(false);

    const handleStopLoad = () => {
        setIsLoading(false);
    };

    const handleStartLoad = () => {
        setIsLoading(true);
    };

    // Handlers

    const handleCurrentPasswordChange = (prop) => (event) =>{
        setCurrentPasswordValues({ ...currentPasswordValues, [prop]: event.target.value });
    }
    const handleCurrentPasswordClickedEye = () => {
        setCurrentPasswordValues({ ...currentPasswordValues, showPassword: !currentPasswordValues.showPassword });
    }
    const handleCurrentPasswordMouseDown = (event) => {
        event.preventDefault();
    };

   const handleEmailChange = (event) => {
       setNewEmail(event.target.value)
   }

   const handleConfirmEmailChange = (event) => {
       setConfirmEmail(event.target.value)
   }

    const handleSubmit = () => {

        if (!currentPasswordValues.password || currentPasswordValues.password.length === 0 ||
            !newEmail || newEmail.length === 0 ||
            !confirmEmail|| confirmEmail.length === 0) {
                setOpenAlert(true)
                setAlertMessage("All fields are required")
                return;
        } 

        if (newEmail !== confirmEmail) {
            setOpenAlert(true)
            setAlertMessage("New email and confirm email fields do not match")
            return;
        }
        handleStartLoad()
        authenticateCurrentPassword(currentPasswordValues.password)
        .then((result) => {
            if (result) {
                updateEmail(org.uid, newEmail)
                .then((result) => {
                    if (result) {
                        history.push('/profile')
                    } else {
                        handleStopLoad()
                        setOpenAlert(true)
                        setAlertMessage("Failed to change email")
                        return;
                    }
                })
            } else {
                setOpenAlert(true)
                setAlertMessage("Incorrect Credentials")
                return;
            }
        })
    }
  return (
    <div className="ChangePassword">
                {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}

                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <div className="ChangePasswordWrapper">
                <h1>Change Email</h1>
                    <div className="ChangePasswordBox">
                        <InputLabel htmlFor="standard-adornment-password" className='ChangePasswordTitle'>
                            Enter your current Password
                        </InputLabel>
                        <Input
                            type={currentPasswordValues.showPassword ? "text" : "password"}
                            onChange={handleCurrentPasswordChange("password")}
                            value={currentPasswordValues.password}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                onClick={handleCurrentPasswordClickedEye}
                                onMouseDown={handleCurrentPasswordMouseDown}
                                >
                                {currentPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />

                        <InputLabel htmlFor="standard-adornment-password" className='ChangePasswordTitle'>
                            Enter transfer email
                        </InputLabel>
                        <Input
                           
                            onChange={handleEmailChange}
                            value={newEmail}
                        />

                        <InputLabel htmlFor="standard-adornment-password" className='ChangePasswordTitle'>
                            Confirm transfer email
                        </InputLabel>
                        <Input
                            onChange={handleConfirmEmailChange}
                            value={confirmEmail}
                        />

                        <button className="PublishButton" disabled={isLoading} onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
    )
}

export default ChangeEmail