import { Container } from 'react-bootstrap';
import React, { useRef } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { useLogin } from '../hooks/useLogin';

const Login = () => {

    const emailRef = useRef()
    const passwordRef = useRef()
   
    const { login, error, isPending} = useLogin()
  

    async function handleSubmit(e) {
        e.preventDefault()
        login(emailRef.current.value, passwordRef.current.value)
    }
  return(
    <>
        <Container className='d-flex align-items-center justify-content-center' 
        style= {{ minHeight: "100vh"}}>

            <div className='w-100' style={{ maxWidth: "400px"}}>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Log In</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required />
                            </Form.Group>

                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required />
                            </Form.Group>
                            <Button disabled={isPending} className="w-100" 
                                    style={{marginTop: '15px', backgroundColor:'#ea2a55', borderColor: '#ea2a55'}} 
                                    type="submit">Log In</Button>
                        </Form>
                    </Card.Body>
                </Card>
                <div className="w-100 text-center mt-2">
                    Don't have an account? Contact Us!
                </div>
            </div>
        </Container>
    </>
  );
};

export default Login;
