import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button'
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';

import getCroppedImg from '../components/Crop/CropImage'
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';
import { useLocation } from 'react-router-dom'
import { mainStorage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import moment from 'moment';
import RestrictedAccess from '../pages/RestrictedAccess'
import '../pages/Banner.css'


export const EditBanner = ( { uid, org }) => {

    const history = useHistory()
    let location = useLocation()

    useEffect(() => {
        if (location && location.state) {
            setBannerTitle(location.state.bannerTitle)
            setUrlLink(location.state.urlLink)
            setBannerDocID(location.state.bannerDocID)
            setTyneProjectBannerID(location.state.tyneProjectBannerID)
            setBannerOwnerID(location.state.owner)
        }
        
    }, [location])
    
    // States
    const [bannerOwnerID, setBannerOwnerID] = useState(null)
    const [tyneProjectBannerID, setTyneProjectBannerID] = useState(null)
    const [bannerDocID, setBannerDocID] = useState(null)
    const [bannerTitle, setBannerTitle] = useState(null);
    const [urlLink, setUrlLink] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [startDateRaw, setStartDateRaw] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateRaw, setEndDateRaw] = useState(null);
    
    // Firestore
    const { updateDocument, checkBannerExists, response } = useFirestore('orgs')
    const { updateDocumentToMain } = useMainFirestore()

     // Alert 
     const [openAlert, setOpenAlert] = useState(false);
     const [alertMessage, setAlertMessage] = useState('');
 
     const handleClose = (event, reason) => {
         if (reason === 'clickaway') {
           return;
         }
         setAlertMessage('')
         setOpenAlert(false);
     };
 
      // Loading 
      const [isLoading, setIsLoading] = useState(false);
      const handleStopLoad = () => {
          setIsLoading(false);
      };
      const handleStartLoad = () => {
          setIsLoading(true);
      };


    const handlePublish = () => {

        const currentDate = moment(Date());
        const subEndDate = moment(org.membershipEndDate.toDate());
        const isAfter = currentDate.isAfter(subEndDate, "day")

        if (isAfter) {
            setOpenAlert(true)
            setAlertMessage("You must be subscribed to a plan to publish a banner")
            return;
        }

        if (bannerTitle === null || bannerTitle === "") {
            setOpenAlert(true)
            setAlertMessage("Banner title is empty")
            return;
        }

        if (image === null) {
            setOpenAlert(true)
            setAlertMessage("Image missing")
            return;
        }

        // Date validation
        if (startDate === null || endDate === null) {
            setOpenAlert(true)
            setAlertMessage("Missing start or end date")
            return
        }
        if (startDate < new Date()) {
            setOpenAlert(true)
            setAlertMessage("Start date cannot happen in the past")
            return
        }
        if (endDate <= startDate) {
            setOpenAlert(true)
            setAlertMessage("End date must occur later than start date")
            return
        }

        const storageRef = mainStorage.ref()
        const imageUUID = uuidv4();
        const filePath = 'Orgs/'+uid+'/'+imageUUID+'.png'
        const fileRef = storageRef.child(filePath)
        const uploadTask = fileRef.put(imgBlob)
        
        handleStartLoad()
        checkBannerExists(org.uid, startDate, endDate)
        .then((isExist) => {
            if (isExist.result === false) {
                handleStopLoad()
                if (isExist.error === null) {
                    setOpenAlert(true)
                    setAlertMessage("A banner you created previously coincides with the timeframe for this banner. You are only allowed one banner at a time. Please remove the previous banner or choose another date.")
                    return
                } else {
                    setOpenAlert(true)
                    setAlertMessage("Error checking banner valid status. Please try again later. If the issue persists, please contact admin@tyne.app")
                    return
                }
            } else {
                uploadTask.on("state_changed",
                    (snapshot) => {},
                    (error) => {
                        handleStopLoad()
                        console.log(error.message)
                    },
                    () => {
                        mainStorage.ref(filePath)
                        .getDownloadURL()
                        .then((url) => {
                            updateDocumentToMain(org,'OrgBanner', tyneProjectBannerID, {
                                created: startDate,
                                expires: endDate,
                                gotoUrl: urlLink,
                                image: url,
                                orgID: org.uid,
                            }).then(() => {
                                handleStopLoad()
                                updateDocument(uid, 'Banners', bannerDocID, {
                                    owner: uid,
                                    imageURL: url,
                                    bannerTitle,
                                    urlLink,
                                    startDate,
                                    endDate
                                })
                            })
                        })
                    }
                )
            }
        })
    }

    useEffect(() => {
        if (response.success) {
           history.push('/banner/manage')
        }
    }, [response.success, history])

    const handleTitleChanged = (e) => {
        setBannerTitle(e.target.value);
    }

    const handleURLChanged = (e) => {
        setUrlLink(e.target.value);
    }

    // Crop States
    const [openCrop, setOpenCrop] = useState(false)
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [image, setImage] = useState(null);
    const [imgBlob, setImgBlob] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({x:0, y:0});
    const [zoom, setZoom] = useState(1)

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixles) => {
        setCroppedArea(croppedAreaPixles);
    }

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.addEventListener('load', ()=> {
                setImage(reader.result);
            });
        }
    };

    const triggerCrop = async() => {
        const canvas = await getCroppedImg(image, croppedArea);
        canvas.toBlob(callback => {
            setImage(canvas.toDataURL('image/png', 1.0))
            setImgBlob(callback)
            setOpenCrop(false);
        }, 'image/png', 1.0)
        
        
    }
    const handleStartDateChanged = (e) => {
        setStartDateRaw(e.target.value)
        const date = new Date(e.target.value)
        setStartDate(date)
    }
    const handleEndDateChanged = (e) => {
        setEndDateRaw(e.target.value)
        const date = new Date(e.target.value)
        setEndDate(date)
    }
    return (
        
            openCrop ?
            <div className='CropContainer'>
            <div className='container-cropper'>
            {image ? (
                    <>
                    <div className='cropper'>
                        <Cropper 
                        image={image} 
                        crop={crop} 
                        zoom={zoom} 
                        aspect={18/4} 
                        onCropChange={setCrop} 
                        onZoomChange={setZoom} 
                        onCropComplete={onCropComplete}
                        />
                    </div>
                    <div className='slider'>
                        <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)}></Slider> 
                    </div>
                    </>
                ) : null}
            </div>
            <div className='container-buttons'>
                <input 
                    type='file' 
                    accept="image/jpg, image/jpeg, image/png" 
                    ref={inputRef} 
                    style={{display:'none'}}
                    onChange={onSelectFile} 
                />

                <Button 
                    variant='contained' 
                    onClick={triggerFileSelectPopup}
                    style={{marginRight: "10px"}}
                >
                    Choose New Image 
                </Button>

                <Button 
                    variant='contained'
                    onClick={triggerCrop}
                >
                    Save
                </Button>
            </div>
        </div>

            :
            
            (bannerOwnerID === uid) ? (
            <div className="NewBanner">
                
                {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}

                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <div className="NewBannerWrapper">
                <h1>Edit Banner</h1>
                    <div className="NewBannerBox">
                        <p className="TitleInfo">Title (Only seen by you)</p>
                        <input placeholder='Title' className='TextFieldInput' value={bannerTitle ? bannerTitle : ""} onChange={handleTitleChanged}/>

                        <p className="TitleInfo">Attach Banner Image</p>
                        <button className="CropButton" onClick={setOpenCrop}>Select Image</button>
                        <img src={image} alt='' className='CroppedImage'></img>

                        <p className="TitleInfo">Social Media URL (Directed to this link when user clicks your banner)</p>
                        <input placeholder='url link' className='TextFieldInput' value={urlLink ? urlLink : ""} onChange={handleURLChanged}/>

                        <p className='dateTitle'>Start Date</p>
                        <input type="datetime-local" onChange={handleStartDateChanged} value={startDateRaw ? startDateRaw : ""} className='DatePicker'/>
                          
                        <p className='dateTitle'>End Date</p>
                        <input type="datetime-local" onChange={handleEndDateChanged} value={endDateRaw ? endDateRaw : ""} className='DatePicker'/>
                           
                    
                        <button className="PublishButton" disabled={isLoading} onClick={handlePublish}>
                            Publish
                        </button>
                    </div>
                </div>
            </div>
            )
            :
            <RestrictedAccess />
        );
    };
