import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css"

import Profile from './pages/Profile'
import {UploadBanner, ManageBanner} from './pages/Banner'
import { EditBanner } from '../src/components/EditBanner'

import { ManageAnnouncement, UploadAnnouncement } from './pages/Announcement';
import Login from './pages/Login';
import { useAuthContext } from './hooks/useAuthContext'
import Home from './pages/Home';
import { EditAnnouncement } from './components/EditAnnouncement';
import { ManageEvent, UploadEvent } from './pages/Event';
import { EditEvent } from './components/EditEvent';
import ContactUs from './pages/Contact';
import ChangePassword from './pages/ChangePassword';
import ChangeEmail from './pages/ChangeEmail';
import Subscription from './pages/Subscription';


function App() {
  const { authIsReady, user, org } = useAuthContext()

  return (
    <>
      {authIsReady && (
         <Router>
           {user && <Sidebar />}
            
            <Switch>
              <Route exact path="/"> 
                {!user && <Redirect to="/login" />}
                {user && org && <Profile org = {org} />}
              </Route>

              <Route exact path='/login'>
                {user && <Redirect to="/" />}
                {!user && <Login />}
              </Route>

              {/* <Route exact path = '/profile'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <Profile org={org} />}
              </Route> */}

              <Route exact path = '/profile/changePassword'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <ChangePassword />}
              </Route>

              <Route exact path = '/profile/changeEmail'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <ChangeEmail org={org} />}
              </Route>

              <Route exact path = '/contact'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <ContactUs org={org} />}
              </Route>

              <Route exact path = '/banner/upload'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <UploadBanner uid={user.uid} org={org} />}
              </Route>
             
              <Route exact path = '/banner/manage'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <ManageBanner uid={user.uid} org={org} />}
              </Route>

              <Route exact path = '/banner/edit/:id'>
                {!user && <Redirect to="/login" />}
                
                {user && org && <EditBanner uid={user.uid} org={org} />}
              </Route>

              <Route exact path = '/announcement/new'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <UploadAnnouncement uid={user.uid} org={org} />}
              </Route>

              <Route exact path = '/announcement/manage'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <ManageAnnouncement uid={user.uid} org={org} />}
              </Route>

              <Route exact path = '/announcement/edit/:id'>
                {!user && <Redirect to="/login" />}
                
                {user && org && <EditAnnouncement uid={user.uid} org={org} />}
              </Route>

              <Route exact path = '/event/new'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <UploadEvent uid={user.uid} org={org} />}
              </Route>
              
              <Route exact path = '/event/manage'>
                {!user && <Redirect to="/login" />}
                {user && org &&  <ManageEvent uid={user.uid} org={org} />}
              </Route>

              <Route exact path = '/event/edit/:id'>
                {!user && <Redirect to="/login" />}
                
                {user && org && <EditEvent uid={user.uid} org={org} />}
              </Route>

              <Route exact path = '/subscribe'>
                {!user && <Redirect to="/login" />}
                {user && org && <Subscription uid={user.uid} org={org} />}
              </Route>

            </Switch>
          </Router>
      )}
    </>
  );
}

export default App;
