import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button'
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';
import TextField from '@mui/material/TextField';

import getCroppedImg from '../components/Crop/CropImage'
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';
import { mainStorage, timestamp } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

import { useCollection }  from '../hooks/useNestedCollection';
import ManageAnnouncementList from '../components/ManageAnnouncementList';
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';

import moment from 'moment';
import './Announcement.css'


export const UploadAnnouncement = ( { uid, org }) => {
   
    const history = useHistory()

    // States
    const [announcementTitle, setAnnouncementTitle] = useState(null);
    const [titleArray, setTitleArray] = useState([]);
    const [description, setDescription] = useState(null );

    // Firestore
    const { addDocument, response } = useFirestore('orgs')
    const { addDocumentToMain } = useMainFirestore()

    // Alert 
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertMessage('')
        setOpenAlert(false);
    };

     // Loading 
     const [isLoading, setIsLoading] = useState(false);
     const handleStopLoad = () => {
         setIsLoading(false);
     };
     const handleStartLoad = () => {
         setIsLoading(true);
     };

    const handlePublish = () => {

        const currentDate = moment(Date());
        const subEndDate = moment(org.membershipEndDate.toDate());
        const isAfter = currentDate.isAfter(subEndDate, "day")

        if (isAfter) {
            setOpenAlert(true)
            setAlertMessage("You must be subscribed to a plan to publish an announcement")
            return;
        }

        if (announcementTitle === null || announcementTitle === "" || titleArray.count === 0) {
            setOpenAlert(true)
            setAlertMessage("Announcement title is empty")
            return;
        }

        if (image === null) {
            setOpenAlert(true)
            setAlertMessage("Image is empty")
            return;
        }
        
        const storageRef = mainStorage.ref()
        const imageUUID = uuidv4();
        const filePath = 'Orgs/'+uid+'/'+imageUUID+'.png'
        const fileRef = storageRef.child(filePath)
        const uploadTask = fileRef.put(imgBlob)
        const created = timestamp.fromDate(new Date())
        
        handleStartLoad()

        uploadTask.on("state_changed",
            (snapshot) => {},
            (error) => {
                handleStopLoad()
                console.log(error.message)
            },
            () => {
                mainStorage.ref(filePath)
                .getDownloadURL()
                .then((url) => {
                    addDocumentToMain(org.collegeAcronym, org.uid, 'OrgAnnoucement', {
                        title: announcementTitle,
                        title_array: titleArray,
                        images: [url],
                        orgID: org.uid,
                        org_name: org.orgAcronym,
                        description: description,
                        liked_count: 0,
                        comment_count: 0,
                        comment_uid: [],
                        liked_uid: [],
                        created,
                    }).then((docID) => {
                        handleStopLoad()
                        addDocument(uid, 'Announcements', {
                            title: announcementTitle,
                            description: description,
                            created,
                            owner: uid,
                            imageURL: url,
                            announcementTitle,
                            tyneProjectDocID: docID,
                        })
                    })
                })
            }
        )
    }

    useEffect(() => {
        if (response.success) {
            history.push('/announcement/manage')
        }
    }, [response.success, history])

    const handleTitleChanged = (e) => {
        setAnnouncementTitle(e.target.value);
        var arr = e.target.value.split(' ');
        setTitleArray(arr)
    }

    const handleDescriptionChanged = (e) => {
        setDescription(e.target.value);
    }

    // Crop States
    const [openCrop, setOpenCrop] = useState(false)
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [image, setImage] = useState(null);
    const [imgBlob, setImgBlob] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({x:0, y:0});
    const [zoom, setZoom] = useState(1)

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixles) => {
        setCroppedArea(croppedAreaPixles);
    }

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.addEventListener('load', ()=> {
                setImage(reader.result);
            });
        }
    };

    const triggerCrop = async() => {
        const canvas = await getCroppedImg(image, croppedArea);
        canvas.toBlob(callback => {
            setImage(canvas.toDataURL('image/png', 1.0))
            setImgBlob(callback)
            setOpenCrop(false);
        }, 'image/png', 1.0)
        
        
    }
    return (
        
            openCrop ?
            <div className='CropContainer'>
            <div className='container-cropper'>
            {image ? (
                    <>
                    <div className='cropper'>
                        <Cropper 
                        image={image} 
                        crop={crop} 
                        zoom={zoom} 
                        aspect={1} 
                        onCropChange={setCrop} 
                        onZoomChange={setZoom} 
                        onCropComplete={onCropComplete}
                        />
                    </div>
                    <div className='slider'>
                        <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)}></Slider> 
                    </div>
                    </>
                ) : null}
            </div>
            <div className='container-buttons'>
                <input 
                    type='file' 
                    accept="image/jpg, image/jpeg, image/png" 
                    ref={inputRef} 
                    style={{display:'none'}}
                    onChange={onSelectFile} 
                />

                <Button 
                    variant='contained' 
                    onClick={triggerFileSelectPopup}
                    style={{marginRight: "10px"}}
                >
                    Choose New Image 
                </Button>

                <Button 
                    variant='contained'
                    onClick={triggerCrop}
                >
                    Save
                </Button>
            </div>
        </div>

            :

            <div className="NewAnnouncement">
                {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}

                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>

                <div className="NewAnnouncementWrapper">
                <h1>New Announcement</h1>
                    <div className="NewBannerBox">
                        <p className="TitleInfo">Title</p>
                        <input placeholder='Title' className='TextFieldInput' value={announcementTitle ? announcementTitle : ""} onChange={handleTitleChanged}/>

                        <p className='TitleInfo'>Description</p>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Describe here"
                            multiline
                            maxRows={10}
                            value={description ? description : ""}
                            onChange={handleDescriptionChanged}
                         />

                        <p className="TitleInfo">Attach announcement Image (1:1 Image Ratio)</p>
                        <button className="CropButton" onClick={setOpenCrop}>Select Image</button>
                        <img src={image} alt='' className='Announcement-CroppedImage'></img>

                    
                        <button className="PublishButton" disabled={isLoading} onClick={handlePublish}>
                            Publish
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    export const ManageAnnouncement = ({ uid, org }) => {

        const { documents, error } = useCollection('orgs', uid, 'Announcements')
       
        return (
            <div className='ManageBannerContainer'>
                <div className="manageBanner">
                    <p>{error}</p>
                    {error && <p>{error}</p>}
                    {documents && <ManageAnnouncementList announcements={documents} uid={uid} org={org} />}
                </div>
            </div>
    
        )
    };