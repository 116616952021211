import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Button } from '@material-ui/core';
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';
import { Link } from 'react-router-dom';


const ManageAnnouncementList = ( { announcements, uid, org }) => {
  const { deleteDocument } = useFirestore('orgs')
  const { deleteDocumentToMain } = useMainFirestore()

  const handleDelete = (announcement) => {
    deleteDocument(uid, 'Announcements', announcement.id)
    deleteDocumentToMain(org, "OrgAnnoucement", announcement.tyneProjectDocID)
  }

  return (
    <>
      <h2 style={{textAlign: "center"}}>Manage Announcements</h2>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="center">Announcement</TableCell>
            <TableCell align="center">Image</TableCell>
            <TableCell align="center">Created Date</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {announcements.map((announcement) => (
            
            <TableRow
              key={announcement.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {announcement.title}
              </TableCell>
              <TableCell align="center" style={{width: "45%"}}>{announcement.description}</TableCell>
              <TableCell align="center"><img src={announcement.imageURL} alt='' style={{width: '75%'}}/></TableCell>
    
              <TableCell align="center">{announcement.created.toDate().toDateString()}</TableCell>
             
              <TableCell align="center">
              <Link
              to={{
                pathname: "/announcement/edit/"+announcement.id,
                state: { 
                  announcementTitle: announcement.title,
                  description: announcement.description,
                  owner: announcement.owner,
                  tyneProjectID: announcement.tyneProjectDocID,
                  announcementDocID: announcement.id,
                  titleArray: announcement.title_array
                }
              }}
            
              >Edit</Link>
               
                <Button size="small" variant="contained" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDelete(announcement) } }>Delete</Button>
              </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
};

export default ManageAnnouncementList;
