import React from 'react'
import './RestrictedAccess.css'

const RestrictedAccess = () => {
  return (
    <div className='RestrictedAccessContainer'>
        <h3>You do not have access to this page</h3>
    </div>
  )
}
export default RestrictedAccess