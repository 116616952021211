import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase" 

export const useCollection = (collection, docID, subCollection) => {
    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        let ref = projectFirestore.collection(collection).doc(docID).collection(subCollection)

        const unsubscribe = ref.orderBy('created', 'desc').onSnapshot((snapshot) => {
            let results = []
            console.log(results)
            snapshot.docs.forEach(doc => {
                results.push({ ...doc.data(), id: doc.id})
            })
           
            // update state
            setDocuments(results)
            setError(null)
        }, (error) => {
            console.log(error)
            setError('Could not fetch data')
        })

        // Unsubscribe on unmount
        return () => unsubscribe()
    }, [collection, docID, subCollection])
    return { documents, error }
}