import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import { Button } from '@material-ui/core';
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';
import { Link } from 'react-router-dom';

const ManageBannerList = ( { banners, uid, org }) => {
  const currentDate = new Date()
  const Active = "Active"
  const Inactive = "Inactive"
  const { deleteDocument } = useFirestore('orgs')
  const { deleteDocumentToMain } = useMainFirestore()

  
  
  const handleDelete = (banner) => {
    deleteDocument(uid, 'Banners', banner.id)
    deleteDocumentToMain(org, "OrgBanner", banner.tyneProjectDocID)
  }

  return (
    <>
      <h2 style={{textAlign: "center"}}>Manage Banners</h2>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align="center">Image</TableCell>
            <TableCell align="center">Go To URL</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {banners.map((banner) => (
            
            <TableRow
              key={banner.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {banner.bannerTitle}
              </TableCell>
              <TableCell align="center"><img src={banner.imageURL} alt='' style={{width: '75%'}}/></TableCell>
              <TableCell align="center">{banner.urlLink}</TableCell>
              <TableCell align="center">{banner.startDate.toDate().toDateString()}, {banner.startDate.toDate().toLocaleTimeString('en-US')} - {banner.endDate.toDate().toDateString()}, {banner.endDate.toDate().toLocaleTimeString('en-US')} </TableCell>
             
              <TableCell align="center">{(moment(currentDate).isAfter(banner.startDate.toDate()) && moment(currentDate).isBefore(banner.endDate.toDate())) ? Active : Inactive}</TableCell>
              
             
              <TableCell align="center">
              <Link
              to={{
                pathname: "/banner/edit/"+banner.id,
                state: { 
                  bannerTitle: banner.bannerTitle,
                  urlLink: banner.urlLink,
                  bannerDocID: banner.id,
                  tyneProjectBannerID: banner.tyneProjectDocID,
                  owner: banner.owner,
                }
              }}
            
              >Edit</Link>
               
                <Button onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDelete(banner) } }>Delete</Button>
              </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
};

export default ManageBannerList;
