import React, {useState} from 'react';
import './Profile.css'
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ReactLoading from 'react-loading'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, } from '@mui/material';
const Profile = ({org}) => {
  
  let history = useHistory()

   // Loading 
   const [isLoading, setIsLoading] = useState(false);
   const handleStopLoad = () => {
       setIsLoading(false);
   };
   const handleStartLoad = () => {
       setIsLoading(true);
   };

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleClickOpen = () => {
    setOpenAlert(true);
  };
  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleGoToChangePassword = () => {
    history.push('/profile/changePassword')
  }

  const handleGoToChangeEmail = () => {
    history.push('/profile/changeEmail')
  }

  const handleCancelSubscription = () => {
    handleClose()
    handleStartLoad()
    const headers = {
      'Content-Type': 'application/json',
    }
    axios.post("https://us-central1-tyne-orgs.cloudfunctions.net/app/cancelSubscription", {
          orgID: org.uid,
          subscriptionID: org.subscriptionID,
          endPeriod: org.membershipEndDate,
        }, {
          headers: headers,
      })
      .then(function(response) {
          handleStopLoad()
          if (response.data.success === true) {
              window.location.reload();
          } else {
            console.log("Failed")
              // setOpenAlert(true)
              // setAlertMessage("Failed to unsubscribe. Please contact admin@tyne.app for assistance.")
              return;
          }
      })
      .catch(function (error) {
        handleStopLoad()
        console.log(error)
      })
  }

  const handleChangePayment = () => {
    handleStartLoad()
    const headers = {
      'Content-Type': 'application/json',
    }

    axios.post("https://us-central1-tyne-orgs.cloudfunctions.net/app/changePaymentMethod", {
          customerID: org.stripeCustomerID,
          subscriptionID: org.subscriptionID,
        }, {
          headers: headers,
      })
      .then(function(response) {
          handleStopLoad()
          if (response.data.success === true) {
              window.location = response.data.url;
          } else {
            console.log("Failed")
              // setOpenAlert(true)
              // setAlertMessage("Failed to unsubscribe. Please contact admin@tyne.app for assistance.")
              return;
          }
      })
      .catch(function (error) {
        handleStopLoad()
        console.log(error)
      })
  }
  const currentDate = moment(Date());
  const subEndDate = moment(org.membershipEndDate.toDate());
  const isAfter = currentDate.isAfter(subEndDate, "day")
  
  const subscriptionEnded = isAfter ? true : false;
  const subscriptionIDPresent = org.subscriptionID !== "" ? true : false
  const shouldCancelAppear = subscriptionIDPresent && !subscriptionEnded;
  const [isCancelButtonAppear, setIsCancelButtonAppear] = useState(shouldCancelAppear);

  const membershipEndString = () => {
    const currentDate = moment(Date());
    const subEndDate = moment(org.membershipEndDate.toDate());
    const isAfter = currentDate.isAfter(subEndDate, "day")

    if (isAfter) {
      return "Membership Ended"
    } else {
      return moment(org.membershipEndDate.toDate().toString()).format("YYYY-MM-DD")
    }
  }

  const membershipPlanString = () => {
    const currentDate = moment(Date());
    const subEndDate = moment(org.membershipEndDate.toDate());
    const isAfter = currentDate.isAfter(subEndDate, "day")

    if (isAfter) {
      return "No Plan"
    } else {
      return org.membershipPlan
    }
  }
 
  return (
    <div className="ProfileContainer">
                
                <div className="ProfileWrapper">
                  <h1>My Org</h1>
                  {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}
                  <Dialog
                    open={openAlert}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Are you sure you want to continue to unsubscribe?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Your plan will continue until the membership expiry period stated below.
                        You will not be charged the following billing period.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={handleCancelSubscription} autoFocus>
                        Unsubscribe
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <div className="ProfileBox">
                    <p className="ProfileInfoTitle">Organization Name</p>
                    <p className="ProfileInfoText">{org.name}</p>
                    
                    <p className="ProfileInfoTitle">Organization Acronym</p>
                    <p className="ProfileInfoText">{org.orgAcronym}</p>

                    <p className="ProfileInfoTitle">College</p>
                    <p className="ProfileInfoText">{org.college}</p>

                    <div style={{display: "flex"}}>
                      <p className="ProfileInfoTitle">Registered Email</p>
                      <button type="button" className='ProfileChangeButton' onClick={handleGoToChangeEmail}>Change</button>
                    </div>
                    <p className="ProfileInfoText">{org.email}</p>
                
                    <button className="ProfileChangePasswordButton" onClick={handleGoToChangePassword} >
                        Change Password
                    </button>
                  </div>

                  <div className="ProfileBox" style={{marginTop: "2rem"}}> 
                    <p className="ProfileInfoTitle">Membership Subscription Status</p>
                      <p className="ProfileInfoText">{subscriptionIDPresent ? "Subscribed" : "Unsubscribed"}</p>
                    
                      <p className="ProfileInfoTitle">Membership Plan</p>
                      <p className="ProfileInfoText">{membershipPlanString()}</p>

                      <p className="ProfileInfoTitle">Membership Expiry Date</p>
                      <p className="ProfileInfoText">{membershipEndString()}</p>
                        
                      {isCancelButtonAppear && <Button variant="contained"
                                                sx={{}}
                                                onClick={() => handleChangePayment()}
                                                >Change Payment Method
                                                </Button>}
                      {isCancelButtonAppear && <Button variant="contained" 
                                                className='ProfileCancelButton' 
                                                onClick={handleClickOpen}
                                                color="error"
                                                sx={{marginTop: "1rem", marginBottom: "1rem"}}
                                                >Cancel Subscription
                                                </Button>}
                  </div>
                </div>
            </div>
  )
};

export default Profile;
