import React, { useEffect, useState } from 'react';
import './Banner.css'
import Button from '@material-ui/core/Button'
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';

import getCroppedImg from '../components/Crop/CropImage'
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';

import { useCollection }  from '../hooks/useNestedCollection';
import ManagerBannerList  from '../components/ManageBannerList'
import { mainStorage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

import { useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import ReactLoading from 'react-loading'
import moment from 'moment';

export const UploadBanner = ( { uid, org }) => {

    const history = useHistory()

    // States
    const [bannerTitle, setBannerTitle] = useState(null);
    const [urlLink, setUrlLink] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [startDateRaw, setStartDateRaw] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateRaw, setEndDateRaw] = useState(null);
    
    // Alert 
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertMessage('')
        setOpenAlert(false);
    };

    // Loading 

    const [isLoading, setIsLoading] = useState(false);

    const handleStopLoad = () => {
        setIsLoading(false);
    };

    const handleStartLoad = () => {
        setIsLoading(true);
    };

    // Firestore
    const { addDocument, checkBannerExists, response } = useFirestore('orgs')
    const { addDocumentToMain } = useMainFirestore()

    const handlePublish = () => {

        const currentDate = moment(Date());
        const subEndDate = moment(org.membershipEndDate.toDate());
        const isAfter = currentDate.isAfter(subEndDate, "day")

        if (isAfter) {
            setOpenAlert(true)
            setAlertMessage("You must be subscribed to a plan to publish a banner")
            return;
        }
        if (bannerTitle === null || bannerTitle === "") {
            setOpenAlert(true)
            setAlertMessage("Banner title is empty")
            return;
        }

        if (image === null) {
            setOpenAlert(true)
            setAlertMessage("Image missing")
            return;
        }

        // Date validation
        if (startDate === null || endDate === null) {
            setOpenAlert(true)
            setAlertMessage("Missing start or end date")
            return
        }
        if (startDate < new Date()) {
            setOpenAlert(true)
            setAlertMessage("Start date cannot happen in the past")
            return
        }
        if (endDate <= startDate) {
            setOpenAlert(true)
            setAlertMessage("End date must occur later than start date")
            return
        }


        const storageRef = mainStorage.ref()
        const imageUUID = uuidv4();
        const filePath = 'Orgs/'+uid+'/'+imageUUID+'.png'
        const fileRef = storageRef.child(filePath)
        const uploadTask = fileRef.put(imgBlob)
        
        handleStartLoad()
        // Need to check if there is a overlapping banner time (only 1 banner at a time for orgs)
        checkBannerExists(org.uid, startDate, endDate)
        .then((isExist) => {
            if (isExist.result === false) {
                handleStopLoad()
                if (isExist.error === null) {
                    setOpenAlert(true)
                    setAlertMessage("A banner you created previously coincides with the timeframe for this banner. You are only allowed one banner at a time. Please remove the previous banner or choose another date.")
                    return
                } else {
                    setOpenAlert(true)
                    setAlertMessage("Error checking banner valid status. Please try again later. If the issue persists, please contact admin@tyne.app")
                    return
                }
            } else {
                // Upload process
                uploadTask.on("state_changed",
                (snapshot) => {},
                (error) => {
                    handleStopLoad()
                    console.log(error.message)
                },
                () => {
                    mainStorage.ref(filePath)
                    .getDownloadURL()
                    .then((url) => {
                        addDocumentToMain(org.collegeAcronym, org.uid, 'OrgBanner', {
                            created: startDate,
                            expires: endDate,
                            gotoUrl: urlLink,
                            image: url,
                            orgID: org.uid,
                        }).then((docID) => {
                            handleStopLoad()
                            addDocument(uid, 'Banners', {
                                owner: uid,
                                tyneProjectDocID: docID,
                                imageURL: url,
                                bannerTitle,
                                urlLink,
                                startDate,
                                endDate
                            })
                        })
                    })
                }
                )
            }
        })
    }

    useEffect(() => {
        if (response.success) {
           history.push('/banner/manage')
        }
    }, [response.success, history])

    const handleTitleChanged = (e) => {
        setBannerTitle(e.target.value);
    }

    const handleURLChanged = (e) => {
        setUrlLink(e.target.value);
    }

    // Crop States
    const [openCrop, setOpenCrop] = useState(false)
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [image, setImage] = useState(null);
    const [imgBlob, setImgBlob] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({x:0, y:0});
    const [zoom, setZoom] = useState(1)

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixles) => {
        setCroppedArea(croppedAreaPixles);
    }

    const onSelectFile = (event) => {
        console.log(event);
        if (event.target.files && event.target.files.length > 0) {
            console.log("hi")
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.addEventListener('load', ()=> {
                console.log(reader.result);
                setImage(reader.result);
            });
        }
    };

    const triggerCrop = async() => {
        const canvas = await getCroppedImg(image, croppedArea);
        canvas.toBlob(callback => {
            setImage(canvas.toDataURL('image/png', 1.0))
            setImgBlob(callback)
            setOpenCrop(false);
        }, 'image/png', 1.0)
        
        
    }
    const handleStartDateChanged = (e) => {
        setStartDateRaw(e.target.value)
        const date = new Date(e.target.value)
        setStartDate(date)
    }
    const handleEndDateChanged = (e) => {
        setEndDateRaw(e.target.value)
        const date = new Date(e.target.value)
        setEndDate(date)
    }
    return (
            openCrop ?
            <div className='CropContainer'>
            <div className='container-cropper'>
            {image ? (
                    <>
                    <div className='cropper'>
                        <Cropper 
                        image={image} 
                        crop={crop} 
                        zoom={zoom} 
                        aspect={18/4} 
                        onCropChange={setCrop} 
                        onZoomChange={setZoom} 
                        onCropComplete={onCropComplete}
                        />
                    </div>
                    <div className='slider'>
                        <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)}></Slider> 
                    </div>
                    </>
                ) : null}
            </div>
            <div className='container-buttons'>
                <input 
                    type='file' 
                    accept="image/jpg, image/jpeg, image/png" 
                    ref={inputRef} 
                    style={{display:'none'}}
                    onChange={onSelectFile} 
                />

                <Button 
                    variant='contained' 
                    onClick={triggerFileSelectPopup}
                    style={{marginRight: "10px"}}
                >
                    Choose New Image 
                </Button>

                <Button 
                    variant='contained'
                    onClick={triggerCrop}
                >
                    Save
                </Button>
            </div>
        </div>

            :
            
            <div className="NewBanner">
                {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <div className="NewBannerWrapper">
                <h1>New Banner</h1>
                    <div className="NewBannerBox">
                        <p className="TitleInfo">Title (Only seen by you)</p>
                        <input placeholder='Title' className='TextFieldInput' value={bannerTitle ? bannerTitle : ""} onChange={handleTitleChanged}/>

                        <p className="TitleInfo">Attach Banner Image (18:4 Image Ratio)</p>
                        <button className="CropButton" onClick={setOpenCrop}>Select Image</button>
                        <img src={image} alt='' className='CroppedImage'></img>

                        <p className="TitleInfo">Social Media URL (Directed to this link when user clicks your banner)</p>
                        <input placeholder='url link' className='TextFieldInput' value={urlLink ? urlLink : ""} onChange={handleURLChanged}/>

                        
                        <p className='dateTitle'>Start Date</p>
                        <input type="datetime-local" onChange={handleStartDateChanged} value={startDateRaw ? startDateRaw : ""} className='DatePicker'/>
                         
                        <p className='dateTitle'>End Date</p>
                        <input type="datetime-local" onChange={handleEndDateChanged} value={endDateRaw ? endDateRaw : ""} className='DatePicker'/>
                          
                        
                    
                        <button className="PublishButton" disabled={isLoading} onClick={handlePublish} >
                            Publish
                        </button>
                    </div>
                </div>
            </div>
            
            
        );
    };

export const ManageBanner = ({ uid, org }) => {
    const { documents, error } = useCollection('orgs', uid, 'Banners')
   
    return (
        <div className='ManageBannerContainer'>
            <div className="manageBanner">
                <p>{error}</p>
                {error && <p>{error}</p>}
                {documents && <ManagerBannerList banners={documents} uid={uid} org={org} />}
            </div>
        </div>

    )
};


