import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button'
import Cropper from 'react-easy-crop';
import { Slider, TextField } from '@material-ui/core';

import getCroppedImg from '../components/Crop/CropImage'
import { useFirestore } from '../hooks/useFirestore'
import { useMainFirestore } from '../hooks/useMainFirestore';
import { useLocation } from 'react-router-dom'
import { mainStorage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';

import RestrictedAccess from '../pages/RestrictedAccess'
import '../pages/Announcement.css'

import moment from 'moment';

export const EditAnnouncement = ( { uid, org }) => {

    const history = useHistory()
    let location = useLocation()

    useEffect(() => {
        if (location && location.state) {
           setAnnouncementTitle(location.state.announcementTitle)
           setTitleArray(location.state.titleArray)
           setAnnouncementDesc(location.state.description)
           setAnnouncementOwnerID(location.state.owner)
           setAnnouncementDocID(location.state.announcementDocID)
           setTyneProjectAnnouncementID(location.state.tyneProjectID)
        }
        
    }, [location])
    
    // States
    const [announcementOwnerID, setAnnouncementOwnerID] = useState(null)
    const [tyneProjectAnnouncementID, setTyneProjectAnnouncementID] = useState(null)
    const [announcementDocID, setAnnouncementDocID] = useState(null)
    const [announcementTitle, setAnnouncementTitle] = useState(null);
    const [titleArray, setTitleArray] = useState([]);
    const [announcementDesc, setAnnouncementDesc] = useState(null);
    
    
    // Firestore
    const { updateDocument, response } = useFirestore('orgs')
    const { updateDocumentToMain } = useMainFirestore()


         // Alert 
         const [openAlert, setOpenAlert] = useState(false);
         const [alertMessage, setAlertMessage] = useState('');
     
         const handleClose = (event, reason) => {
             if (reason === 'clickaway') {
               return;
             }
             setAlertMessage('')
             setOpenAlert(false);
         };
     
          // Loading 
          const [isLoading, setIsLoading] = useState(false);
          const handleStopLoad = () => {
              setIsLoading(false);
          };
          const handleStartLoad = () => {
              setIsLoading(true);
          };
    

    const handlePublish = () => {

        const currentDate = moment(Date());
        const subEndDate = moment(org.membershipEndDate.toDate());
        const isAfter = currentDate.isAfter(subEndDate, "day")

        if (isAfter) {
            setOpenAlert(true)
            setAlertMessage("You must be subscribed to a plan to publish an announcement")
            return;
        }

        if (announcementTitle === null || announcementTitle === "" || titleArray.count === 0) {
            setOpenAlert(true)
            setAlertMessage("Empty title")
            return;
        }

        if (image === null) {
            setOpenAlert(true)
            setAlertMessage("Empty image")
            return;
        }
        
        const storageRef = mainStorage.ref()
        const imageUUID = uuidv4();
        const filePath = 'Orgs/'+uid+'/'+imageUUID+'.png'
        const fileRef = storageRef.child(filePath)
        const uploadTask = fileRef.put(imgBlob)
   
        handleStartLoad()

        uploadTask.on("state_changed",
            (snapshot) => {},
            (error) => {
                handleStopLoad()
                console.log(error.message)
            },
            () => {
                mainStorage.ref(filePath)
                .getDownloadURL()
                .then((url) => {
                    updateDocumentToMain(org, 'OrgAnnoucement', tyneProjectAnnouncementID, {
                        title: announcementTitle,
                        title_array: titleArray,
                        images: [url],
                        description: announcementDesc,
                    }).then(() => {
                        handleStopLoad()
                        updateDocument(uid, 'Announcements', announcementDocID, {
                            title: announcementTitle,
                            description: announcementDesc,
                            imageURL: url,
                        })
                    })
                })
            }
        )
    }

    
    useEffect(() => {
        if (response.success) {
            history.push('/announcement/manage')
        }
    }, [response.success, history])

    const handleTitleChanged = (e) => {
        setAnnouncementTitle(e.target.value);
        var arr = e.target.value.split(' ');
        setTitleArray(arr)
    }

    const handleDescriptionChanged = (e) => {
        setAnnouncementDesc(e.target.value);
    }

    // Crop States
    const [openCrop, setOpenCrop] = useState(false)
    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => inputRef.current.click();
    const [image, setImage] = useState(null);
    const [imgBlob, setImgBlob] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({x:0, y:0});
    const [zoom, setZoom] = useState(1)

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixles) => {
        setCroppedArea(croppedAreaPixles);
    }

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.addEventListener('load', ()=> {
                setImage(reader.result);
            });
        }
    };

    const triggerCrop = async() => {
        const canvas = await getCroppedImg(image, croppedArea);
        canvas.toBlob(callback => {
            setImage(canvas.toDataURL('image/png', 1.0))
            setImgBlob(callback)
            setOpenCrop(false);
        }, 'image/png', 1.0)
        
        
    }
    return (
        
        openCrop ?
        <div className='CropContainer'>
        <div className='container-cropper'>
        {image ? (
                <>
                <div className='cropper'>
                    <Cropper 
                    image={image} 
                    crop={crop} 
                    zoom={zoom} 
                    aspect={1} 
                    onCropChange={setCrop} 
                    onZoomChange={setZoom} 
                    onCropComplete={onCropComplete}
                    />
                </div>
                <div className='slider'>
                    <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)}></Slider> 
                </div>
                </>
            ) : null}
        </div>
        <div className='container-buttons'>
            <input 
                type='file' 
                accept="image/jpg, image/jpeg, image/png" 
                ref={inputRef} 
                style={{display:'none'}}
                onChange={onSelectFile} 
            />

            <Button 
                variant='contained' 
                onClick={triggerFileSelectPopup}
                style={{marginRight: "10px"}}
            >
                Choose New Image 
            </Button>

            <Button 
                variant='contained'
                onClick={triggerCrop}
            >
                Save
            </Button>
        </div>
    </div>

        :
        (announcementOwnerID === uid) ? (
        <div className="NewAnnouncement">
            {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>

            <div className="NewAnnouncementWrapper">
            <h1>New Announcement</h1>
                <div className="NewBannerBox">
                    <p className="TitleInfo">Title</p>
                    <input placeholder='Title' className='TextFieldInput' value={announcementTitle ? announcementTitle : ""} onChange={handleTitleChanged}/>

                    <p className='TitleInfo'>Description</p>
                    <TextField
                        id="outlined-multiline-flexible"
                        label="Describe here"
                        multiline
                        maxRows={10}
                        value={announcementDesc ? announcementDesc : ""}
                        onChange={handleDescriptionChanged}
                     />

                    <p className="TitleInfo">Attach announcement Image (1:1 Image Ratio)</p>
                    <button className="CropButton" onClick={setOpenCrop}>Select Image</button>
                    <img src={image} alt='' className='Announcement-CroppedImage'></img>

                
                    <button className="PublishButton" disabled={isLoading} onClick={handlePublish}>
                        Publish
                    </button>
                </div>
            </div>
        </div>
        )
        :
        <RestrictedAccess />
        );
    };
