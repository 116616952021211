import React, { useEffect, useState } from 'react'
import './ChangePassword.css'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { useProfileManager } from '../hooks/useProfileManager';

import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

const ChangePassword = () => {
    const history = useHistory()
    const { authenticateCurrentPassword, updatePassword } = useProfileManager()
    
    // States
    const [currentPasswordValues, setCurrentPasswordValues] = useState({
        password: "",
        showPassword: false,
    });
    const [newPasswordValues, setNewPasswordValues] = useState({
        password: "",
        showPassword: false,
    });
    const [confirmPasswordValues, setConfirmPasswordValues] = useState({
        password: "",
        showPassword: false,
    });

    // Alert 
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertMessage('')
        setOpenAlert(false);
    };

    // Loading 

    const [isLoading, setIsLoading] = useState(false);

    const handleStopLoad = () => {
        setIsLoading(false);
    };

    const handleStartLoad = () => {
        setIsLoading(true);
    };

    // Handlers

    const handleCurrentPasswordChange = (prop) => (event) =>{
        setCurrentPasswordValues({ ...currentPasswordValues, [prop]: event.target.value });
    }
    const handleCurrentPasswordClickedEye = () => {
        setCurrentPasswordValues({ ...currentPasswordValues, showPassword: !currentPasswordValues.showPassword });
    }
    const handleCurrentPasswordMouseDown = (event) => {
        event.preventDefault();
    };

    const handleNewPasswordChange = (prop) => (event) =>{
        setNewPasswordValues({ ...newPasswordValues, [prop]: event.target.value });
    }
    const handleNewPasswordClickedEye = () => {
        setNewPasswordValues({ ...newPasswordValues, showPassword: !newPasswordValues.showPassword });
    }
    const handleNewPasswordMouseDown = (event) => {
        event.preventDefault();
    };

    const handleConfirmPasswordChange = (prop) => (event) =>{
        setConfirmPasswordValues({ ...confirmPasswordValues, [prop]: event.target.value });
    }
    const handleConfirmPasswordClickedEye = () => {
        setConfirmPasswordValues({ ...confirmPasswordValues, showPassword: !confirmPasswordValues.showPassword });
    }
    const handleConfirmPasswordMouseDown = (event) => {
        event.preventDefault();
    };


    const handleSubmit = () => {

        if (!currentPasswordValues.password || currentPasswordValues.password.length === 0 ||
            !newPasswordValues.password || newPasswordValues.password.length === 0 ||
            !confirmPasswordValues.password || confirmPasswordValues.length === 0) {
                setOpenAlert(true)
                setAlertMessage("All fields are required")
                return;
        } 

        if (newPasswordValues.password !== confirmPasswordValues.password) {
            setOpenAlert(true)
            setAlertMessage("New password and confirm password don't match")
            return;
        }

        var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        var testStrength = regularExpression.test(newPasswordValues.password)
        if (!testStrength) {
            setOpenAlert(true)
            setAlertMessage("Your password must: be 8-16 characters, have at least one number, have at least one special character")
            return;
        }

        handleStartLoad()
        authenticateCurrentPassword(currentPasswordValues.password).then((result) => {
            if (result) {
                updatePassword(newPasswordValues.password)
                .then((result) => {
                    if (result) {
                        history.push('/profile')
                    } else {
                        handleStopLoad()
                        setOpenAlert(true)
                        setAlertMessage("Failed to change password");
                        return;
                    }
                })
            } else {
                handleStopLoad()
                setOpenAlert(true)
                setAlertMessage("Your current password is incorrect");
                return;
            }
        })
    }
  return (
    <div className="ChangePassword">
                {isLoading  && <ReactLoading type="spin" className="Loading" color={"red"} height={'5%'} width={'5%'} />}

                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <div className="ChangePasswordWrapper">
                <h1>Change Password</h1>
                    <div className="ChangePasswordBox">
                        <InputLabel htmlFor="standard-adornment-password" className='ChangePasswordTitle'>
                            Enter your current Password
                        </InputLabel>
                        <Input
                            type={currentPasswordValues.showPassword ? "text" : "password"}
                            onChange={handleCurrentPasswordChange("password")}
                            value={currentPasswordValues.password}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                onClick={handleCurrentPasswordClickedEye}
                                onMouseDown={handleCurrentPasswordMouseDown}
                                >
                                {currentPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />

                        <InputLabel htmlFor="standard-adornment-password" className='ChangePasswordTitle'>
                            Enter new Password
                        </InputLabel>
                        <Input
                            type={newPasswordValues.showPassword ? "text" : "password"}
                            onChange={handleNewPasswordChange("password")}
                            value={newPasswordValues.password}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                onClick={handleNewPasswordClickedEye}
                                onMouseDown={handleNewPasswordMouseDown}
                                >
                                {newPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />

                        <InputLabel htmlFor="standard-adornment-password" className='ChangePasswordTitle'>
                            Confirm Password
                        </InputLabel>
                        <Input
                            type={confirmPasswordValues.showPassword ? "text" : "password"}
                            onChange={handleConfirmPasswordChange("password")}
                            value={confirmPasswordValues.password}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                onClick={handleConfirmPasswordClickedEye}
                                onMouseDown={handleConfirmPasswordMouseDown}
                                >
                                {confirmPasswordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />

                        <button className="PublishButton" disabled={isLoading} onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
    )
}

export default ChangePassword